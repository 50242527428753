@tailwind base;
@layer base {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-[#2b2b2b];
    }

    p {
        @apply text-[#3D4147];
    }
}
@tailwind components;
@tailwind utilities;
