.data-container {
    @apply flex h-96 w-full flex-col justify-between p-5 lg:w-1/2;
}

.data-container .description {
    @apply px-5;
}

.data-container .description > h1 {
    @apply text-xl font-bold;
}

.data-container .description > p {
    @apply text-sm;
}

.data-container .data {
    @apply h-60 w-full rounded-2xl border p-5;
}
